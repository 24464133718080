import { TAX_HIGH, TAX_HIGH_FACTOR } from '.';

export interface Product {
  unitPrice: number | null;
  taxRate: number | null;
  isSale?: boolean;
  dishwashFactor?: number;
}

export interface CartItem {
  product: Product;
  quantity: number;
}

export const calculateProductPrice = (
  product: Product,
  quantity: number,
  isTaxIncluded: boolean
) => {
  const price = product.unitPrice || 0;
  const tax = isTaxIncluded ? calculateProductTax(product) : 0;

  return ((price + tax) * quantity) / 100;
};

export const calculateTaxHigh = (price: number) => {
  return Math.round(price * TAX_HIGH_FACTOR);
};

export const calculateProductTax = (product: Product) => {
  const taxRate = (product.taxRate || 0) / 100;
  const unitPrice = product.unitPrice || 0;

  return unitPrice * taxRate;
};

export const calculateTotalPrice = ({
  cartItems,
  isTaxIncluded,
  isProductIncluded = true,
  tierOfPricingFactor = 1,
  matchRate,
}: {
  cartItems: CartItem[];
  isTaxIncluded: boolean;
  isProductIncluded?: boolean;
  tierOfPricingFactor?: number;
  matchRate?: number;
}) => {
  if (!cartItems?.length) return 0;

  return cartItems.reduce((a, i) => {
    const taxRate = i.product?.taxRate || 0;
    if (matchRate && matchRate !== taxRate) return a;

    const tierOfPricingMultiplier = i.product?.isSale ? 1 : tierOfPricingFactor;
    const productPrice =
      calculateProductPrice(i.product, i.quantity, isTaxIncluded) -
      (isProductIncluded ? 0 : calculateProductPrice(i.product, i.quantity, false));
    const tierOfPricingPrice = productPrice * tierOfPricingMultiplier;

    return a + tierOfPricingPrice;
  }, 0);
};

export const subtractTransportCostTax = (isDelivery: boolean, transportCostWithTax?: number) => {
  return isDelivery
    ? transportCostWithTax
      ? Math.round((transportCostWithTax * 100) / (100 + TAX_HIGH)) / 100
      : 0
    : 0;
};

export const calculateDishwashCost = (
  cartItems: CartItem[],
  dishwashCost: number | undefined,
  withDishwashing: boolean
) => {
  return withDishwashing
    ? Math.round(
        cartItems.reduce(
          (a, i) => a + (i.product?.dishwashFactor || 0) * (dishwashCost || 0) * i.quantity,
          0
        )
      ) / 100
    : 0;
};

export const round = (n: number) => Math.round(n * 100) / 100;
