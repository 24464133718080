import { SVGProps } from 'react';

export const ImgNotAvailable: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_608_3895)">
        <path
          d="M4.43258 1.07929C3.5066 0.153284 2.00527 0.153252 1.07929 1.07923C0.153284 2.0052 0.153252 3.50654 1.07923 4.43252L5.07414 8.4275C4.10536 9.99523 3.54628 11.8429 3.54628 13.8212V50.1786C3.54628 55.8532 8.1465 60.4535 13.8212 60.4535H50.1786C52.1567 60.4535 54.004 59.8945 55.5718 58.9262L59.5664 62.9211C60.4924 63.8471 61.9938 63.8471 62.9198 62.9211C63.8458 61.9951 63.8458 60.4937 62.9198 59.5676L4.43258 1.07929ZM32.2152 35.5689L51.8349 55.1893C51.895 55.2598 51.9547 55.33 52.0138 55.3995C51.4397 55.6015 50.8219 55.7112 50.1786 55.7112H13.8212C13.1767 55.7112 12.5579 55.6009 11.9826 55.3986L30.3407 37.4212L32.2152 35.5689ZM29.2143 32.568C28.4204 32.9057 27.6756 33.3938 27.023 34.0327L8.61501 52.0556C8.40372 51.4694 8.28855 50.8374 8.28855 50.1786V13.8212C8.28855 13.169 8.40142 12.5432 8.60865 11.9621L29.2143 32.568Z"
          fill="#637381"
        />
        <path
          d="M55.7114 45.6528V13.8218C55.7114 10.7662 53.2343 8.28914 50.1787 8.28914H18.3484L13.6084 3.54902C13.6792 3.5476 13.7502 3.54688 13.8213 3.54688H50.1787C55.8533 3.54688 60.4536 8.1471 60.4536 13.8218V50.1791C60.4536 50.2506 60.453 50.322 60.4514 50.3929L55.7114 45.6528Z"
          fill="#637381"
        />
        <path
          d="M42.2817 14.6113C46.214 14.6113 49.4017 17.7991 49.4017 21.7314C49.4017 25.6637 46.214 28.8514 42.2817 28.8514C38.3494 28.8514 35.1616 25.6637 35.1616 21.7314C35.1616 17.7991 38.3494 14.6113 42.2817 14.6113ZM42.2817 19.3536C40.9684 19.3536 39.9039 20.4182 39.9039 21.7314C39.9039 23.0447 40.9684 24.1092 42.2817 24.1092C43.595 24.1092 44.6594 23.0447 44.6594 21.7314C44.6594 20.4182 43.595 19.3536 42.2817 19.3536Z"
          fill="#637381"
        />
      </g>
      <defs></defs>
    </svg>
  );
};
