'use client';

import * as React from 'react';
import { Trash2, X } from 'lucide-react';

import { Button } from '@av/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@av/ui/drawer';

import { Table, TableBody, TableCell, TableRow } from '@av/ui/table';
import { useCart } from './cart-context';
import Link from 'next/link';
import { getProductPath } from '@/lib/get-product-path';
import { ImgNotAvailable } from '@/assets/img-not-available';
import { calculateProductPrice, formatter, getCartTotal } from '@av/calculations';
import Counter from '@/components/form/counter/counter';
import { usePathname } from 'next/navigation';
import { useTaxState } from '../context/tax-state-context';

export function CartDrawer() {
  const {
    showCart,
    cartItems,
    openCart,
    closeCart,
    updateCartItemQuantity,
    deleteCartItem,
    cartItemsCount,
  } = useCart();
  const pathname = usePathname();

  const { isTaxIncluded } = useTaxState();

  const cartTotal = getCartTotal({ cartItems, isTaxIncluded });

  return (
    <Drawer open={showCart} onOpenChange={(b: boolean) => (b ? openCart() : closeCart())}>
      <DrawerContent className="h-full w-[45rem] max-w-full">
        <DrawerHeader className="w-full">
          <DrawerTitle className="flex items-center justify-between">
            Winkelwagen
            <button onClick={() => closeCart()} className="p-3">
              <X className="size-6" />
            </button>
          </DrawerTitle>
        </DrawerHeader>

        {cartItems?.length ? (
          <div className="flex flex-1 flex-col overflow-auto py-2 md:py-8">
            <Table className="border-none">
              <TableBody>
                {cartItems &&
                  cartItems.map(({ product, ...cartItem }) => {
                    const productPriceTotal = calculateProductPrice(
                      product,
                      cartItem.quantity,
                      isTaxIncluded
                    );
                    const productPriceUnit = calculateProductPrice(product, 1, isTaxIncluded);

                    return (
                      !!product && (
                        <TableRow key={cartItem.id} data-testid="cart-item">
                          <TableCell className="py-8 pl-0">
                            <div className="flex w-full gap-2 max-md:flex-col">
                              <div className="flex w-full space-x-2 md:items-center">
                                <div className="relative mr-2 aspect-square size-14 min-w-14 rounded bg-white md:mr-5 md:size-20 md:min-w-20">
                                  {product.thumbnailImage ? (
                                    <img
                                      src={product.thumbnailImage}
                                      className="size-14 object-contain md:size-20"
                                      alt={product.title}
                                    />
                                  ) : (
                                    <ImgNotAvailable className="size-full p-2 md:p-5" />
                                  )}
                                </div>
                                <div className="w-[calc(100%-70px)] gap-2 md:flex md:items-center">
                                  <div className="flex grow gap-2">
                                    <Link
                                      className="w-full"
                                      href={getProductPath(product)}
                                      onClick={() => closeCart()}
                                    >
                                      <p className="whitespace-normal text-base font-semibold md:text-lg">
                                        {product.title}
                                      </p>

                                      <p className="text-muted-foreground whitespace-normal text-sm font-normal md:text-sm">
                                        {cartItem.quantity} x {formatter.format(productPriceUnit)}
                                      </p>
                                    </Link>
                                    <button
                                      onClick={() => deleteCartItem(cartItem.id)}
                                      className="h-min p-1"
                                      data-testid="delete-item"
                                    >
                                      <Trash2 className="h-5 w-auto stroke-red-500 md:hidden" />
                                    </button>
                                  </div>
                                  <div className="mt-2 flex items-center justify-between gap-2 md:gap-5">
                                    <Counter
                                      value={cartItem.quantity}
                                      debounce
                                      onChange={async (quantity) => {
                                        if (cartItem.quantity !== quantity)
                                          await updateCartItemQuantity(cartItem.id, quantity);
                                      }}
                                      step={product.step || 1}
                                      min={product.minQuantity || 1}
                                      setQuantity={product.setQuantity || undefined}
                                      data-testid="quantity-input"
                                    />
                                    <div className="min-w-20 text-right text-lg font-semibold">
                                      {formatter.format(productPriceTotal)}
                                    </div>
                                    <button
                                      onClick={() => deleteCartItem(cartItem.id)}
                                      className="h-min p-1 max-md:hidden"
                                    >
                                      <Trash2 className="h-5 w-auto stroke-red-500" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </TableBody>
            </Table>
            <div className="ml-auto mt-auto">
              <div className="text-right text-xl font-medium">
                Totaalbedrag: {formatter.format(cartTotal)}
              </div>
              <div className="text-right text-base text-gray-500">
                {isTaxIncluded ? 'Incl. BTW' : 'Excl. BTW'}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex size-full items-center justify-center">
            <p className="m-auto max-w-sm px-5 py-10 text-center text-sm font-light text-muted-fg">
              Geen artikelen in je winkelwagen.{' '}
              <Link
                href="/assortiment"
                className="font-semibold text-primary"
                onClick={() => closeCart()}
              >
                Klik hier
              </Link>{' '}
              om het assortiment te bekijken.
            </p>
          </div>
        )}

        <DrawerFooter>
          {!!cartItemsCount && (
            <DrawerClose asChild>
              <Button href="/winkelwagen/bestellen" data-testid="proceed-to-checkout">
                Verder naar bestellen
              </Button>
            </DrawerClose>
          )}
          <DrawerClose asChild>
            <Button
              href={pathname.includes('/assortiment') ? pathname : '/assortiment'}
              variant="outline"
            >
              {cartItemsCount ? 'Meer artikelen toevoegen' : 'Artikelen toevoegen'}
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
