import {
  CartItem,
  TAX_HIGH,
  TAX_HIGH_FACTOR,
  TAX_LOW,
  calculateDishwashCost,
  calculateTotalPrice,
  subtractTransportCostTax,
  round,
} from '.';

export const getTaxAmountTierOfPricing = ({
  cartItems,
  tierOfPricingFactor,
  matchRate,
}: {
  cartItems: CartItem[];
  tierOfPricingFactor: number;
  matchRate?: number;
}) => {
  return round(
    calculateTotalPrice({
      cartItems: cartItems,
      isTaxIncluded: true,
      isProductIncluded: false,
      tierOfPricingFactor: tierOfPricingFactor,
      matchRate: matchRate,
    })
  );
};

export const getTotalTierOfPricing = ({
  cartItems,
  tierOfPricingFactor,
  isTaxIncluded,
}: {
  cartItems: CartItem[];
  isTaxIncluded: boolean;
  tierOfPricingFactor: number;
}) => {
  return round(
    calculateTotalPrice({
      cartItems,
      isTaxIncluded,
      isProductIncluded: true,
      tierOfPricingFactor,
    })
  );
};

export const getTotalAmountDishwash = ({
  cartItems,
  dishwashCost,
  withDishwashing,
  isTaxIncluded,
}: {
  cartItems: CartItem[];
  dishwashCost: number;
  withDishwashing: boolean;
  isTaxIncluded: boolean;
}) => {
  return round(
    (1 + (isTaxIncluded ? TAX_HIGH_FACTOR : 0)) *
      round(calculateDishwashCost(cartItems, dishwashCost, withDishwashing))
  );
};

export const getTotalAmountTransport = ({
  isDelivery,
  transportCost,
  isTaxIncluded,
}: {
  isDelivery: boolean;
  transportCost: number;
  isTaxIncluded: boolean;
}) => {
  return round(
    (1 + (isTaxIncluded ? TAX_HIGH_FACTOR : 0)) *
      round(subtractTransportCostTax(isDelivery, transportCost))
  );
};

export const getTaxAmountTotal = ({
  cartItems,
  tierOfPricingFactor,
  dishwashCost,
  transportCost,
  withDishwashing,
  isDelivery,
}: {
  cartItems: CartItem[];
  tierOfPricingFactor: number;
  dishwashCost: number;
  transportCost: number;
  withDishwashing: boolean;
  isDelivery: boolean;
}) => {
  const taxAmountTierOfPricingLow = getTaxAmountTierOfPricing({
    cartItems,
    tierOfPricingFactor,
    matchRate: TAX_LOW,
  });
  const taxAmountTierOfPricingHigh = getTaxAmountTierOfPricing({
    cartItems,
    tierOfPricingFactor,
    matchRate: TAX_HIGH,
  });
  const taxAmountTransport = TAX_HIGH_FACTOR * subtractTransportCostTax(isDelivery, transportCost);
  const taxAmountDishwash =
    TAX_HIGH_FACTOR * calculateDishwashCost(cartItems, dishwashCost, withDishwashing);

  return round(
    taxAmountTierOfPricingLow + taxAmountTierOfPricingHigh + taxAmountDishwash + taxAmountTransport
  );
};

export const getCartTotal = ({
  cartItems,
  isTaxIncluded,
}: {
  cartItems: CartItem[];
  isTaxIncluded: boolean;
}) => {
  return round(calculateTotalPrice({ cartItems, isTaxIncluded, isProductIncluded: true }));
};

export const getTotal = ({
  totalTierOfPricing,
  totalAmountDishwash,
  totalAmountTransport,
  taxAmountTotal,
}: {
  totalTierOfPricing: number;
  totalAmountDishwash: number;
  totalAmountTransport: number;
  taxAmountTotal: number;
}) => {
  return round(totalTierOfPricing + totalAmountDishwash + totalAmountTransport + taxAmountTotal);
};
