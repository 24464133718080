import { Button } from '@av/ui/button';

interface PaginationProps {
  currentPage: number;
  perPage: number;
  totalItems: number;
  // eslint-disable-next-line no-unused-vars
  onPageChange: (page: number) => void;
}

function Pagination({ currentPage, perPage, totalItems, onPageChange }: PaginationProps) {
  const totalPages = Math.ceil(totalItems / perPage);

  if (totalPages <= 1) {
    return null;
  }

  const getPageNumbers = (): (number | string)[] => {
    const pageNumbers: (number | string)[] = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (currentPage > 3) {
        pageNumbers.push('...');
      }

      const start = Math.max(2, currentPage - 1);
      const end = Math.min(totalPages - 1, currentPage + 1);

      for (let i = start; i <= end; i++) {
        if (!pageNumbers.includes(i)) {
          pageNumbers.push(i);
        }
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex items-center justify-end gap-2 py-3">
      {/* Paginanummers knoppen */}
      {pageNumbers.map((page, index) => (
        <Button
          className={`disabled:opacity-100 rounded-md ${
            typeof page === 'number' && page === currentPage
              ? 'bg-blue-600 text-white'
              : 'bg-white hover:bg-gray-200'
          }`}
          disabled={page === '...' || (typeof page === 'number' && page === currentPage)}
          key={index}
          onClick={() => {
            if (typeof page === 'number') {
              handlePageChange(page);
            }
          }}
          size="icon"
          variant="secondary"
        >
          {page}
        </Button>
      ))}
    </div>
  );
}

export { Pagination };
