'use client';

import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { CartDrawer } from './cart-drawer';
import { CartItem, Product } from '@av/database';
import { toast } from 'react-hot-toast';
import { api } from '@/trpc/react';
import { useTaxState } from '../context/tax-state-context';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';

export type CartItems = (CartItem & { product: Product })[];

export const CartContext = createContext({
  showCart: false,
  cartTotal: 0,
  cartTaxAmount: 0,
  cartItemsCount: 0,
  cartItems: [] as CartItems,
  openCart: () => {},
  closeCart: () => {},
  /* eslint-disable no-unused-vars */
  deleteCartItem: async (_: number) => {},
  /* eslint-disable no-unused-vars */
  updateCartItemQuantity: async (_: number, __: number) => {},
});

export interface CartContextType {
  showCart: boolean;
  cartTotal: number;
  cartTaxAmount: number;
  cartItemsCount: number;
  cartItems: CartItems;
  openCart: () => void;
  closeCart: () => void;
  /* eslint-disable no-unused-vars */
  deleteCartItem: (id: number) => Promise<void>;
  /* eslint-disable no-unused-vars */
  updateCartItemQuantity: (id: number, quantity: number) => Promise<void>;
}

export const useCart = () => useContext<CartContextType>(CartContext);

const CartProvider = ({ children }: { children: ReactNode }) => {
  const [showCart, setShowCart] = useState(false);
  const utils = api.useUtils();
  const { data, update } = useSession();
  const { isTaxIncluded } = useTaxState();
  const router = useRouter();

  const cartItems = data?.user.cart?.items || [];

  const openCart = () => {
    setShowCart(true);
  };

  const closeCart = () => {
    setShowCart(false);
  };

  const DeleteCartItem = api.cartItem.delete.useMutation();
  const UpdateCartItemQuantity = api.cartItem.updateCount.useMutation();

  const deleteCartItem = async (id: number) => {
    await DeleteCartItem.mutateAsync(id);
    await utils.cartItem.invalidate();
    toast.success('Artikel is verwijderd uit je winkelwagen.', { id: 'delete-cart-item' });
    update();
    router.refresh();
  };

  const updateCartItemQuantity = useCallback(
    async (id: number, quantity: number) => {
      await UpdateCartItemQuantity.mutateAsync({
        id,
        quantity,
      });

      update();
      router.refresh();
    },
    [update]
  );

  const cartTotal =
    useMemo(() => {
      return (
        cartItems?.reduce((a, i) => {
          const taxMultiplier = isTaxIncluded ? 1 + (i.product.taxRate || 0) / 100 : 1;

          return (
            a +
            (i.product ? Math.round((i.product.unitPrice || 0) * taxMultiplier) * i.quantity : 0)
          );
        }, 0) || 0
      );
    }, [cartItems, isTaxIncluded]) / 100;

  const cartTaxAmount =
    useMemo(() => {
      return (
        cartItems?.reduce((a, i) => {
          const taxMultiplier = (i.product.taxRate || 0) / 100;

          return (
            a +
            (i.product ? Math.round((i.product.unitPrice || 0) * taxMultiplier) * i.quantity : 0)
          );
        }, 0) || 0
      );
    }, [cartItems]) / 100;

  const store = useMemo(
    () => ({
      showCart,
      cartItems,
      cartItemsCount: cartItems.length,
      cartTotal,
      cartTaxAmount,
      deleteCartItem,
      updateCartItemQuantity,
      openCart,
      closeCart,
    }),
    [showCart, cartItems, cartTotal, cartTaxAmount]
  );

  return (
    <CartContext.Provider value={store}>
      <CartDrawer />
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
