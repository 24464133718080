'use client';

import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';

const TaxStateContext = createContext({
  isTaxIncluded: true,
  /* eslint-disable no-unused-vars */
  setIsTaxIncluded: (value: boolean) => {},
});

interface TaxStateContextType {
  isTaxIncluded: boolean;
  /* eslint-disable no-unused-vars */
  setIsTaxIncluded: (value: boolean) => void;
}

export const useTaxState = () => useContext<TaxStateContextType>(TaxStateContext);

const TaxStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [isTaxIncluded, setIsTaxIncluded] = useState(false);

  useEffect(() => {
    const sessionTaxState = sessionStorage.getItem('taxState');
    setIsTaxIncluded(sessionTaxState ? JSON.parse(sessionTaxState) : false);
  }, []);

  const store = useMemo(
    () => ({ isTaxIncluded, setIsTaxIncluded }),
    [isTaxIncluded, setIsTaxIncluded]
  );

  return <TaxStateContext.Provider value={store}>{children}</TaxStateContext.Provider>;
};

export default TaxStateProvider;
